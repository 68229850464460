<template>
  <div class="login-wrapper">
    <div
      class="bg-wrapper"
      :class="
        loginType == 3
          ? 'h320'
          : loginType == 2
          ? 'h450'
          : loginType == 3
          ? 'h380'
          : 'w400 h460'
      "
    >
      <!-- 密码登录 -->
      <div class="user-wrapper" v-if="loginType == 1">
        <div
          class="switch_img"
          @click="loginType = 2"
          :style="{ display: showLogin }"
        >
          <img
            src="../../assets/images/login/code_switch.png"
            class="w164 h52"
          />
        </div>
        <el-form :model="loginForm" ref="loginRef">
          <el-form-item style="margin-top: 20px" prop="username">
            <p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
              账号登录
            </p>
          </el-form-item>
          <el-form-item
            style="margin-top: 20px"
            prop="username"
            id="border-none"
          >
            <el-input
              type="password"
              style="position: fixed; bottom: -9999px"
            />
            <el-autocomplete
              class="inline-input"
              type="text"
              size="large"
              auto-complete="new-accounts"
              style="width: 300px"
              v-model="loginForm.username"
              popper-class="showAutocomplete"
              :fetch-suggestions="getHistory"
              placeholder="请输入手机号"
            ></el-autocomplete>
          </el-form-item>

          <el-form-item style="margin-top: 20px" id="border-none">
            <el-input
              type="password"
              style="position: fixed; bottom: -9999px"
            />
            <el-select
              v-model="companyName"
              size="large"
              filterable
              remote
              @focus="queryCompany(loginForm.username)"
              style="width: 300px"
              placeholder="请选择公司"
              @change="selectCompany"
            >
              <el-option
                style="width: 300px"
                v-for="item in oemLogin"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="margin-top: 20px"
            prop="password"
            id="border-none"
          >
            <div class="flex-a-b-c">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                :type="
                  (readonly && loginForm.password) || loginForm.password
                    ? 'password'
                    : 'text'
                "
                :readonly="readonly"
                v-model="loginForm.password"
                size="large"
                style="width: 185px; border: none"
                placeholder="请输入密码"
                @focus="changAttr($event, 'focus')"
                @blur="changAttr($event, 'blur')"
                @keyup.enter.native="handleLogin"
                @click.native="clickEvt"
                ref="password"
                prefix-icon="el-icon-lock"
              ></el-input>
              <el-button
                plain
                type="primary"
                style="width: 100px"
                size="large"
                @click="clearInput((loginType = 4))"
                >忘记密码</el-button
              >
            </div>
          </el-form-item>
          <el-form-item style="margin-top: 30px" align="center">
            <!-- <el-button
              plain
              type="primary"
              style="width: 48%"
              size="large"
              @click="clearInput((loginType = 3))"
              >免费注册体验</el-button
            > -->
            <el-button
              type="primary"
              style="width: 100%"
              size="large"
              @click="handleLogin"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 扫码登录 -->
      <div class="code-wrapper" v-if="loginType == 2">
        <div class="switch_img" @click="loginType = 1">
          <img
            src="../../assets/images/login/phone_switch.png"
            class="w164 h52"
          />
        </div>
        <el-form
          :model="loginForm"
          :rules="loginRules"
          align="center"
          ref="loginRef"
        >
          <iframe
            :src="codeUrl"
            class="w300 h400 mt20"
            scrolling="no"
            frameborder="0"
          >
          </iframe>
        </el-form>
      </div>

      <!-- 体验登录 -->
      <div class="experience-wrapper" v-if="loginType == 3">
        <div class="switch_img" @click="loginType = 1">
          <img
            src="../../assets/images/login/phone_switch.png"
            class="w164 h52"
          />
        </div>
        <el-form :model="registerForm" :rules="loginRules" ref="loginRef">
          <el-form-item style="margin-top: 20px" prop="username">
            <p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
              注册体验
            </p>
          </el-form-item>
          <el-form-item
            style="margin-top: 20px"
            prop="username"
            id="border-none"
          >
            <el-input
              type="password"
              style="position: fixed; bottom: -9999px"
            />
            <el-input
              v-model="registerForm.username"
              size="large"
              style="width: 300px; border: none"
              placeholder="请输入手机号"
              prefix-icon="el-icon-mobile"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="margin-top: 20px"
            prop="password"
            id="border-none"
          >
            <div class="flex-a-b-c">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="registerForm.password"
                auto-complete="new-accounts"
                size="large"
                style="width: 160px; border: none"
                placeholder="请输入验证码"
                prefix-icon="el-icon-lock"
              ></el-input>
              <el-button
                plain
                type="primary"
                :disabled="countDown !== 0 ? true : false"
                size="large"
                @click="getSendCode(registerForm.username)"
                >{{
                  countDown ? `重新发送(${countDown})` : "获取验证码"
                }}</el-button
              >
            </div>
          </el-form-item>

          <el-form-item style="margin-top: 30px">
            <el-button
              plain
              type="primary"
              style="width: 48%"
              size="large"
              @click="clearInput((loginType = 1))"
              >已有账号，去登录</el-button
            >
            <el-button
              type="primary"
              style="width: 48%"
              size="large"
              @click="handleLogin('test')"
              >立即体验</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 忘记密码 -->
      <div class="reset-wrapper" v-if="loginType == 4">
        <div class="switch_img" @click="loginType = 1">
          <img
            src="../../assets/images/login/phone_switch.png"
            class="w164 h52"
          />
        </div>
        <el-form :model="resetForm" ref="loginRef">
          <el-form-item style="margin-top: 20px">
            <p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
              忘记密码
            </p>
          </el-form-item>

          <el-form-item style="margin-top: 20px">
            <div class="flex-a-c">
              <div
                class="flex-a-c"
                v-for="(item, index) in resetState"
                :key="index"
              >
                <div
                  class="w24 h24 br50p be6eb flex-c-c"
                  :class="
                    resetType == item.type
                      ? 'bgbule cfff'
                      : resetType > item.type
                      ? 'cA2 bgfff'
                      : ''
                  "
                  v-if="resetType <= item.type"
                >
                  {{ index + 1 }}
                </div>
                <i class="el-icon-circle-check fs12 cblue" v-else></i>
                <p
                  class="fs7 ml10 cA2"
                  :class="resetType == item.type ? 'cA1 fwbold' : ''"
                >
                  {{ item.title }}
                </p>
                <p class="mr10 ml10" v-if="index !== 2">-</p>
              </div>
            </div>
          </el-form-item>

          <el-form-item
            style="margin-top: 20px"
            id="border-none"
            v-if="resetType == 1"
          >
            <div class="mb10">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="resetForm.username"
                auto-complete="new-accounts"
                size="large"
                style="border: none"
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile"
              ></el-input>
            </div>
            <div class="mb10">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-select
                v-model="companyName"
                auto-complete="new-accounts"
                size="large"
                filterable
                remote
                @focus="queryCompany(resetForm.username)"
                style="width: 100%"
                placeholder="请选择公司"
                @change="selectCompany"
              >
                <el-option
                  style="width: 300px"
                  v-for="item in oemLogin"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="flex-a-b-c">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="resetForm.imgCode"
                auto-complete="new-accounts"
                size="large"
                style="border: none; margin-right: 10px"
                placeholder="图形验证码"
                prefix-icon="el-icon-lock"
              ></el-input>
              <img
                :src="imgCode"
                v-if="imgCode"
                @click="showResetUserInfo"
                class="w100 h40"
              />
              <div class="bgf7fa w100 h40" v-else></div>
              <!-- <el-button plain type="primary" style="width: 80px" size="large" @click="getSendCode">验证码</el-button> -->
            </div>
            <div class="flex-a-b-c mt10">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                v-model="resetForm.messageCode"
                auto-complete="new-accounts"
                size="large"
                style="border: none; margin-right: 10px"
                placeholder="短信验证码"
                prefix-icon="el-icon-lock"
              ></el-input>
              <el-button
                plain
                type="primary"
                :disabled="resetCountDown !== 0 ? true : false"
                size="large"
                @click="getResetSendCode(resetForm.username)"
                >{{
                  resetCountDown ? `重新发送(${resetCountDown})` : "获取验证码"
                }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            style="margin-top: 20px"
            id="border-none"
            v-if="resetType == 2"
          >
            <div class="flex-a-b-c mb20">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                :type="
                  (readonly && resetPwdForm.password) || resetPwdForm.password
                    ? 'password'
                    : 'text'
                "
                :readonly="readonly"
                v-model="resetPwdForm.password"
                auto-complete="new-accounts"
                size="large"
                style="border: none"
                placeholder="请输入新密码"
                prefix-icon="el-icon-mobile"
                @focus="changAttr($event, 'focus')"
                @blur="changAttr($event, 'blur')"
                @click.native="clickEvt"
                ref="password"
              ></el-input>
            </div>
            <div class="flex-a-b-c">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-input
                :type="
                  (readonly && resetPwdForm.secondPassword) ||
                  resetPwdForm.secondPassword
                    ? 'password'
                    : 'text'
                "
                :readonly="readonly"
                v-model="resetPwdForm.secondPassword"
                auto-complete="new-accounts"
                size="large"
                style="border: none"
                placeholder="请重复新密码"
                prefix-icon="el-icon-lock"
                @focus="changAttr($event, 'focus')"
                @blur="changAttr($event, 'blur')"
                @click.native="clickEvt"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item
            style="margin-top: 20px"
            id="border-none"
            v-if="resetType == 3"
          >
            <div class="flex-c-c h80 flex-column">
              <i class="el-icon-circle-check fs24 cblue"></i>
              <p class="cA1 fs14">设置成功</p>
            </div>
          </el-form-item>
          <el-form-item style="margin-top: 30px" v-if="resetType == 1">
            <el-button
              type="primary"
              style="width: 100%"
              size="large"
              @click="checkNumber"
              >下一步</el-button
            >
            <div class="flex-a-b-c mt10 cblue">
              <p
                @click="clearInput((loginType = 3), (resetType = 1))"
                class="hover_pointer"
              >
                免费注册体验
              </p>
              <p
                @click="clearInput((loginType = 1), (resetType = 1))"
                class="hover_pointer"
              >
                返回登录>
              </p>
            </div>
          </el-form-item>
          <el-form-item style="margin-top: 30px" v-else-if="resetType == 2">
            <el-button
              type="primary"
              style="width: 100%"
              size="large"
              @click="changePassword"
              >下一步</el-button
            >
            <div class="flex-a-b-c mt10 cblue">
              <p
                @click="clearInput((loginType = 3), (resetType = 1))"
                class="hover_pointer"
              >
                免费注册体验
              </p>
              <p
                @click="clearInput((loginType = 1), (resetType = 1))"
                class="hover_pointer"
              >
                返回登录>
              </p>
            </div>
          </el-form-item>
          <el-form-item style="margin-top: 30px" v-if="resetType == 3">
            <el-button
              plain
              type="primary"
              style="width: 100%"
              size="large"
              @click="clearInput((loginType = 1), (resetType = 1))"
              >立即登录</el-button
            >
            <div class="flex-c-c mt10 cblue">
              <p
                @click="clearInput((loginType = 3), (resetType = 1))"
                class="hover_pointer"
              >
                免费注册体验
              </p>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/login";
import { getDataTimeSec } from "@/utils/index";
import { queryOemDomain } from "@/api/oemConfig.js";

export default {
  name: "login",

  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      showLogin: "none",
      registerForm: {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      },
      resetForm: {
        username: "",
        imgCode: "",
        messageCode: "",
      },
      resetPwdForm: {
        password: "",
        secondPassword: "",
      },
      companyName: "", // 公司名
      oemLogin: [],
      loginType: 1, //登录方式 1为账号密码登录  2为 扫码登录  3为 体验账号登录 4为 忘记密码
      companyId: "",
      codeUrl: "",
      resetType: 1, //修改密码方式 1为手机号验证  2为 设置新密码  3为 成功
      imgCode: "",
      signature: "", //认证签名
      resetState: [
        {
          title: "安全验证",
          type: 1,
        },
        {
          title: "设置新密码",
          type: 2,
        },
        {
          title: "设置成功",
          type: 3,
        },
      ],
      countDown: 0,
      resetCountDown: 0,
      readonly: true,
    };
  },
  async created() {
    console.log("domain:", document.domain);
    this.getCodeUrl();
    this.getUserInfo();
    this.showResetUserInfo();
    if (
      document.domain != "localhost" &&
      document.domain != "crm.youdomain.com"
    ) {
      this.showLogin = "none";
      const oemConfig = await queryOemDomain({ oemDomain: document.domain });
      if (!oemConfig) {
        this.$message.error("oem配置错误,请联系售后");
        return;
      }
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";

      if (oemConfig) {
        link.href = oemConfig.data.iconLogo;
        document.getElementsByTagName("head")[0].appendChild(link);
        //获取网页标题
        document.title = oemConfig.data.oemName;
      } else {
        link.href =
          "https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/one-stand/icon/favicon.ico";
        document.getElementsByTagName("head")[0].appendChild(link);
        //获取网页标题
        document.title = "壹脉智能CRM";
      }
    } else {
      this.showLogin = "disabled";
    }
  },
  mounted() {},

  methods: {
    //密码的历史记录
    getHistory(queryString, cb) {
      let $cname = document.querySelector(".showAutocomplete");
      let history = JSON.parse(localStorage.getItem("password")),
        arr = [];
      if (history) {
        history.forEach((v, i) => {
          arr.push({
            value: v.value.username,
            password: v.value.password,
          });
        });
      }
      console.log($cname);
      arr.length == 0 ? ($cname.style.display = "none") : "";
      cb(arr);
    },
    // 查询公司
    queryCompany(val) {
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          val
        )
      ) {
        this.loginForm.username = "";
        this.resetForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: val }, { showLoading: false }).then((res) => {
        console.log(res);
        this.oemLogin = res.data;
      });
    },
    getSendCode(phone) {
      if (this.countDown) return;
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          phone
        )
      ) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.countDown = 60;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    getResetSendCode(phone) {
      if (this.resetCountDown) return;
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          phone
        )
      ) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.resetCountDown = 60;
        this.timer = setInterval(() => {
          this.resetCountDown--;
          if (this.resetCountDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    getUserInfo() {
      // let data = {
      //   code:"091LE0100dV9sL1JAp100a9sAR3LE01i",
      //   state:'bm'
      // }
      // api.pcLoginCallBack(data)
      // .then(res=>{
      //   console.log(res)
      // })
    },
    //获取验证码
    getCodeUrl() {
      api
        .fetchCodeUrl()
        .then((res) => {
          this.codeUrl = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择公司
    selectCompany(val) {
      this.companyId = val;
    },
    //获取图形验证码
    showResetUserInfo() {
      let data = {
        phone: this.resetForm.username || "",
      };
      api
        .getVerifiCode(data)
        .then((res) => {
          console.log();
          this.imgCode = "data:image/png;base64," + res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkNumber() {
      if (
        !this.resetForm.username ||
        !this.resetForm.imgCode ||
        !this.resetForm.messageCode
      ) {
        this.$message({
          message: "手机号或验证码为空",
          type: "error",
        });
        return;
      }
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          this.resetForm.username
        )
      ) {
        this.loginForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      let data = {
        imgCode: this.resetForm.imgCode,
        smsCode: this.resetForm.messageCode,
        phone: this.resetForm.username,
      };
      api
        .forgetPwd(data)
        .then((res) => {
          this.showResetUserInfo();
          this.signature = res.data;
          this.resetType = 2;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              title: "警告",
              message: err.message,
              type: "warning",
            });
          }
        });
    },
    changePassword() {
      if (
        this.resetPwdForm.password.length < 6 ||
        this.resetPwdForm.secondPassword.length < 6
      ) {
        this.$message({
          message: "密码长度不得小于六位",
          type: "warning",
        });
        return;
      }
      if (this.resetPwdForm.password !== this.resetPwdForm.secondPassword) {
        this.$message({
          message: "两次输入的密码不一，请重新输入",
          type: "warning",
        });
        return;
      }
      let data = {
        signature: this.signature,
        password: this.resetPwdForm.password,
        companyId: this.companyId,
      };
      api
        .updatePwd(data)
        .then((res) => {
          console.log(res);
          this.resetType = 3;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              message: err.message,
              type: "warning",
            });
            return;
          }
        });
    },
    // 清除登录数据
    clearInput() {
      this.loginForm = {
        username: "",
        password: "",
      };
      this.registerForm = {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      };
      this.resetForm = {
        username: "",
        imgCode: "",
        messageCode: "",
      };
      this.resetPwdForm = {
        password: "",
        secondPassword: "",
      };
      this.companyName = "";
    },
    // 登录请求
    handleLogin(type) {
      //登录时间
      let date = new Date();
      date = getDataTimeSec(date);
      let value = "";
      if (type !== "test") {
        //非体验用户登录
        value = {
          username: this.loginForm.username,
          phone: this.loginForm.username,
          password: this.loginForm.password,
          companyName: this.companyName,
          companyId: this.companyId,
        };
      } else {
        //体验用户登录
        value = {
          username: this.registerForm.username,
          phone: this.registerForm.username,
          // password: this.loginForm.password,
          smsCode: this.registerForm.password,
          companyName: this.registerForm.companyName,
          companyId: this.registerForm.companyId,
        };
      }

      let than = this,
        passwordArr = [], //
        text = "password";

      localStorage.removeItem("password");
      passwordArr.push({ value });

      if (JSON.parse(localStorage.getItem(text))) {
        // 判断是否已有xxx查询记录的localStorage
        if (localStorage.getItem(text).indexOf(value.username) > -1) {
          // 判断是否已有此条查询记录，若已存在，则修改顺序
          let userArr = JSON.parse(localStorage.getItem(text));
          console.log(userArr);
          userArr.forEach((v, k) => {
            console.log(v);
            if (v.value.username == value.username) {
              //将重复的username顺序提前
              let currentUser = userArr.splice(k, 1);
              userArr.unshift(currentUser[0]);
              localStorage.setItem(text, JSON.stringify(userArr));
            }
          });
        } else if (JSON.parse(localStorage.getItem(text)).length >= 5) {
          //记录大于五条时删除最后一条添加新的记录
          let arr = JSON.parse(localStorage.getItem(text));
          arr.pop();
          arr.unshift(passwordArr[0]);
          localStorage.setItem(text, JSON.stringify(arr));
        } else {
          //没有记录且不大于五条记录
          localStorage.setItem(
            text,
            JSON.stringify(
              passwordArr.concat(JSON.parse(localStorage.getItem(text)))
            )
          );
        }
      } else {
        // 首次创建
        localStorage.removeItem("password");
        localStorage.setItem(text, JSON.stringify(passwordArr));
      }
      if (type !== "test") {
        //手机密码登录
        if (!value.username || !value.companyName || !value.password) {
          this.$message.error("账号信息不完整，请填写完整");
          return;
        }

        api
          .fetchLoginByPhone(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = res.data.lastLoginTime;
              this.$store.state.loginRoot.userInfo = res.data;
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.data);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else {
        //体验账号登录
        if (!value.username || !value.smsCode) {
          this.$message.error("账号信息不完整，请填写完整");
          return;
        }
        api
          .fetchLogin(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = date;
              this.$store.state.loginRoot.userInfo = res.data;
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.data);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      }
    },

    changAttr(e, type) {
      if (type === "focus") {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        setTimeout(() => {
          this.readonly = false;
        }, 100);
      } else {
        if (e) {
          e.stopPropagation();
        }
        this.readonly = true;
      }
    },
    clickEvt() {
      if (this.$refs.password) {
        this.$refs.password.$refs.input.onmousedown = (evt) => {
          if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
          }
          if (this.loginForm.password === "" || this.readonly) {
            this.$refs.password.$refs.input.blur();
            setTimeout(() => {
              this.$refs.password.$refs.input.focus();
            }, 0);
          }
          return false;
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/login/login_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.bg-wrapper {
  width: 380px;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 20px rgba(81, 205, 203, 0.08);
  opacity: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  transition: all 0.4s;
  position: relative;
}
.switch_img {
  position: absolute;
  top: 8px;
  right: 8px;
}
.bg-wrapper:hover {
  box-shadow: 0px 0px 40px rgba(39, 148, 175, 0.1);
  transition: all 0.4s;
}
.bg-wrapper .el-input__inner {
  border: none;
}

.user-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px 0;
}

.opacity0 {
  opacity: 1;
  transition: all 0.2s;
}
.opacity1 {
  opacity: 1;
  transition: all 0.2s;
}
.code-wrapper {
  width: 100%;
  height: 100%;
  padding: 30px 0;
}
.experience-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px 0;
}

.reset-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px 0;
}
</style>
